import React, {useState} from "react";
import styles from "./selectbox.module.css";
import openIcon from "./selectbox-icon.png";

export default function Selectbox({values, label, onChange, width}) {
  const [open, setOpen] = useState(false);
  const onSelect = (newValue) => {
    onChange(newValue);
    setOpen(false);
  }
  return (
    <div className={styles.container} style={{width: width}}>
      <div className={styles.valueContainer} style={{width: width}} onClick={() => setOpen(true)}>
        <span>{label || "vyberte"}</span>
        <img src={openIcon} alt={"selectbox icon"} />
      </div>
      {open && <ul className={styles.valuesContainer} style={{width: width+20}}>
        {values.map((v, i) => <li key={v.id} onClick={() => onSelect(v.id)}>{v.label}</li>)}
      </ul>}
    </div>
  )
}