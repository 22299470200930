import React from "react";
import styles from "./checkbox.module.css";

export default function Checkbox({label, checked, onChange}) {
  return (
    <div className={styles.container} onClick={() => onChange(!checked)}>
      <div className={styles.unselectedState}>
        {checked && <div className={styles.selectedState}/> }
      </div>
      <p>{label}</p>
    </div>
  )
}